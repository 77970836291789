/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~font-awesome/css/font-awesome.css";
@import '~material-icons/iconfont/material-icons.css';
body {
  margin: 0;
  padding: 0;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: 'Open Sans', verdana, arial, sans-serif !important;
  font-weight: 400;
  font-size: 14px;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
       url('~material-icons/iconfont/material-icons.woff2') format('woff2')
}
img {
  -ms-interpolation-mode: bicubic;
  outline: none;
  border-style: none;
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

table td {
  border-collapse: collapse;
}

a {
  color: #00a3e0;
  text-decoration: none;
  font-weight: 700;
  outline: 0;
}

a:hover {
  color: #000;
  text-decoration: underline;
}

p,
h1,
h2,
h3 {
  margin-top: 0;
}

p {
  line-height: 20px;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

.title {
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  font-weight: 400;
}

.mat-cell:hover {
  text-decoration: underline;
  color: black;
  font-weight: 700;
  outline: 0;
  cursor: pointer;
}

.mat-cell {


  text-decoration: underline;
  color: #00a3e0;
  text-decoration: none;
  font-weight: 700;
  outline: 0;
  cursor: pointer;
  word-wrap: anywhere;

}

.description {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000000;
}

.main-text {
  font-size: 14px;
  line-height: 20px;
  color: #53565A;
  font-weight: 400;
}

.hint-text {
  font-size: 12px;
  line-height: 16px;
  color: #75787B;
  font-weight: 400;
}

h2 a,
h3 a {
  color: #000;
}

.deloitteheader {
  height: 50px;
  border-collapse: collapse;
  border-spacing: 0;
  background-image: url(assets/images/dias/email-template-deloitte-logo.png);
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: 10px center;
  width: 100%;
}

html,
body {
  margin: 0px;
  padding: 0px;
  min-height: 100%;
  height: 100%;
}

#wrapper {
  min-height: 87.5%;
  background-image: url(assets/images/ciam/ciam-logo.jpg);
  background-repeat: no-repeat;
  background-position: 20px 100px;
}

#wrapper:after {
  content: "";
  display: block;
  height: 50px;
  /* the footer's total height */
}

#footer-section {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  margin: 0 auto;
  background: #282828;
  position: relative;
  z-index: 999;
  color: white;
  height: 10%;
  text-align: left;
}

#footer-section a:hover {
  color: grey;
  text-decoration: none;
}

#footer-section a {
  color: white;
  text-decoration: none;
}


#pre-footer-section {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  color: white;
  height: 2.5%;
  text-align: left;
}

#pre-footer-section a:hover {
  color: grey;
  text-decoration: none;
}

.dislist {
  padding: 2px
}

.panel-title {
  color: black;
  font-weight: 700;
  ;
}

.titleicon {
  float: left;
  width: 50px;
  height: 40px;
  margin-right: 20px;
}

.titleicon img {
  max-height: 100%;
}

.subtitleicon {
  float: left;
  width: 20px;
  height: 30px;
  margin-top: 1px;
  margin-right: 20px;
}

.subtitleicon img {
  max-height: 100%;
}

.breadcrumb-dis {
  /*  margin: 0px; */
}

.tel-input {
  height: 48px;
  width: 100%;
}

.intl-tel-input {
  width: 100%;
}

.breadcrumb-dis li:after {
  content: "";
  margin: 0px;
}

.breadcrumb-dis li {
  font-size: 13px;
  content: "";
  margin: 0px;
}

.breadcrumb-dis li.logout {
  content: "";
  margin: 0px;
  float: right;
  color: #00a3e0;
}

.breadcrumb-dis li.logout:before {
  content: "";
}

.breadcrumbs {
  margin-bottom: 24px;
}

.link-breadcrumbs {
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
  text-decoration: none;
  position: relative;
  font-weight: 400;
}

.link-breadcrumbs:hover {
  text-decoration: underline;
  color: #53565a;
}

.link-breadcrumbs:first-child {
  color: #000000;
}

.manage-credentials-container {
  margin-top: 48px;
}

.manage-credentials-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 24px;
}

.user-details-container {
  margin-top: 24px;
}

.user-details-container-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.user-list-description {
  font-size: 14px;
  line-height: 20px;
  color: #53565a;
}

.detail-edit-page-header {
  justify-content: space-between;
}

.info-user-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #97999b;
  margin-bottom: 8px;
}

.savebutton {
  float: right;
  margin-right: -10px;
}

.accordion-toggle {
  cursor: pointer;
  text-decoration: none;
}

.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-datepicker {
  max-height: none;
  overflow-y: auto;
}
.home-accordion {
  margin-bottom: -4px;
}

/* Fix for Bootstrap 3 with Angular UI Bootstrap */

.modal {
  display: table;
}

.modal-dialog {
  height: 100px;
  width: 400px;
}

.undefined {
  color: grey;
  font-style: italic;
}

table.discontent {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

table.disoutermaincontent {
  background-color: #fff;
  width: 100%;
}

td.disinnercontent {
  width: 100%;
  border-top: 3px solid #86bc24;
  padding: 20px;
}

.poweredby {
  line-height: 0px;
  font-size: 13px;
}

/* ====== ====== */
.ta-editor.form-control.myform1-height,
.ta-scroll-window.form-control.myform1-height {

  height: 80px;
  overflow: auto;
  font-family: inherit;
  font-size: 100%;
  width: 500px;
}

.form-control.myform1-height>.ta-bind {
  height: auto;
  min-height: 300px;
  padding: 6px 12px;
}

.ta-bind {
  overflow: auto;
  font-family: inherit;
  font-size: 100%;
}

.ta-editor.ta-html,
.ta-scroll-window.form-control {
  min-height: 30px !important;
  max-height: 100px;
}

.info {
  margin-bottom: 30px;
}

.log {
  margin-top: 30px;
}

#log {
  max-height: 150px;
}

pre {
  min-height: 50px;
}

.bad {
  color: red;
  font-weight: bold;
}

.good {
  color: green;
  font-weight: bold;
}

li {
  margin-bottom: 10px;
}

a {
  cursor: pointer;
}

.form-group.has-error .help-block {
  display: block;
}

.modal-popup {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content-popup {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 10px;
  border: 1px solid #888;
  width: 30%;
}





.login-page {
  display: flex;
  font-family: 'Open Sans';
  min-height: 100vh;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 440px;
  margin: 0 auto;
}

.logo {
  margin-bottom: 64px;
}

/*
  Disabling this as it disrupts the columns in DIS
  
  .header {
    width: 100%;
  }
  
  */
.form-link {
  text-align: right;
  outline: none;
  padding-bottom: 24px;
  margin-bottom: 0;
}

.form {
  width: 100%;
  max-width: 400px;
  margin-top: 64px;
}

.form-title {
  margin-bottom: 12px;
}

.info-text-footer {
  font-size: 12px;
  line-height: 16px;
}

.form-description {
  margin-bottom: 8px;
}

.form-second-description {
  margin-bottom: 24px;
}

.form-controls {
  margin-bottom: 24px;
  position: relative;
}

.login-btn {
  height: 48px;
}

.btn-black {
  background-color: black;
  color: #ffffff;
}

.btn-white {
  background-color: white;
  border: 1px solid #000000;
  color: black;
}

.btn-white:hover {
  background: #f8f8fa;
  color: black;
  border: 1px solid #1f1f1f;
}

.btn-white:focus,
.btn-white:active {
  background: #f8f8fa;
  color: black;
  outline: none;
  border: 1px solid #333333;
}

.complete-btn {
  width: 100%;
}
.form-horizontal .control-label
{
    text-align: start !important
}

.form-container {
  max-width: 400px;
  width: 100%;
  display: flex;
  padding-bottom: 64px;
  justify-content: center;
}

.info-terms-link-container {
  margin-bottom: 15px;
}

.label-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}

.label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid #d0d0ce;
  border-radius: 50%;
}

.label-text {
  color: #75787b;
}

.label-text-bold {
  font-weight: 600;
}

.label-container input:checked~.checkmark {
  background-color: #000000;
  border: none;
}

.label-container input:checked~.label-text {
  color: black;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.label-container input:checked~.checkmark:after {
  display: block;
}

.label-container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: white;
}

.img-container {
  width: 50%;
  height: auto;
  background-image: url('assets/img/login-page/bg-login-page.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;



}

.footer {
  display: flex;
  align-items: flex-end;
  padding-bottom: 64px;
}

.info-text-footer:first-child {
  padding-bottom: 15px;
}

/* checkbox */

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 28px;
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark-checkbox {
  position: absolute;
  top: 2px;
  left: 0;
  height: 14px;
  border-radius: 0px;
  width: 14px;
  border: none;
  background: #ffffff;
  border: 1px solid #d0d0ce;
}

.container-checkbox input:checked~.checkmark-checkbox {
  background: #000000;
  border: none;
}

.checkmark-checkbox:after {
  content: '';
  position: absolute;
  display: none;
}

.container-checkbox input:checked~.checkmark-checkbox:after {
  display: block;
}

.container-checkbox .checkmark-checkbox:after {
  left: 2px;
  top: 3px;
  width: 10px;
  height: 10px;
  background-image: url(./assets/img/self-service/check.svg);
  background-repeat: no-repeat;
}

.checkbox-label {
  font-size: 14px;
  color: #53565a;
  line-height: 20px;
}

button[disabled] {
  cursor: default;
  background: grey;
}

button[disabled]:hover {
  cursor: default;
  background: grey;
}

.eye-dis {
  display: inline;
  margin-left: -30px;
  color: grey;
  cursor: pointer;
  font-family: FontAwesome
}

.self-service-img-container {
  height: 100vh;
  position: fixed;
  top: 48px;
  z-index: 111;
  right: 0px;
}

.activate-page {
  flex-wrap: wrap;
}

.header-self-service {
  background: #000000;
  height: 48px;
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 5;
  display: flex;
  justify-content: space-between;
}

.drop-down-menu {
  background: #53565a;
  margin-right: 36px;
  width: 28px;
  margin-top: 10px;
  height: 28px;
  text-align: center;
  position: relative;
}

.mobile-method-container {
  padding-left: 24px;
}

.mobile-checkbox-container {
  display: flex;
}

.mobile-method-checkbox {
  margin-right: 49px;
}

.title-successful {
  margin-bottom: 32px;
}

.logo-successful {
  margin-bottom: 36px;
}

.drop-down-menu::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  right: -13px;
  top: 8px;
  background-image: url('assets/img/self-service/arrow-down.svg');
  background-repeat: no-repeat;
}

.drop-down-menu-item {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
}

.logo-white {
  padding-left: 24px;
  padding-top: 12px;
  position: relative;
}

.logo-white::after {
  position: absolute;
  content: '';
  width: 1px;
  background: #53565a;
  right: -17px;
  top: 21px;
  height: 12px;
}

.input-form-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  color: #000000;
}

.input-mb {
  margin-bottom: 14px;
}

.controls-mb {
  margin-bottom: 40px;
}

.configure-method {
  margin-bottom: 38px;
}

.checkbox-form-label {
  margin-bottom: 8px;
}

.label-success {
  position: relative;
}

.label-success::before {
  content: '';
  width: 20px;
  height: 20px;
  right: -39px;
  bottom: 2px;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url('assets/img/self-service/check-success.svg');
}

.label-success-authentication::before {
  content: '';
  width: 20px;
  height: 20px;
  left: 85px;
  bottom: 2px;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url('assets/img/self-service/check-success.svg');
}

.label-success-authentication {
  position: relative;
}

.authenticator-text-step-label {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.authenticator-text-step-description {
  font-size: 14px;
  line-height: 20px;
  color: #53565a;
}

.authenticator-text-step {
  margin-bottom: 32px;
}

.authenticator-container {
  padding-left: 10px;
  padding-right: 10px;
}

.qr-container {
  display: flex;
  align-items: flex-end;
}

.qr-code {
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-left: 16px;
}

.confirm-form {
  display: flex;
  max-width: 376px;
}

.confirm-input {
  border-right: none;
  color: black;
}

.confirm-input:focus {
  border-right: none;
}

.confirm-btn {
  border: 1px solid #000000;
  background-color: white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  color: #000000;
  padding: 13px 38.5px;
  max-width: 132px;
  transition: all 0.5s ease;
}

.confirm-btn:hover {
  background: #f8f8fa;
  border: 1px solid #1f1f1f;
}

.confirm-btn:active {
  background: #f8f8fa;
  border: 1px solid #333333;
}

.confirm-btn:focus {
  background: #f8f8fa;
  border: 1px solid #333333;
}

.confirm-btn:focus {
  outline: none;
}

.confirm-form-send {
  background: #f8f8fa;
  padding: 16px;
  max-width: 376px;
}

.send-password-text {
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
}

.main-text-activation-successful {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.validate-btn {
  padding: 13px 20.5px;
  white-space: pre;
  max-width: 132px;
  width: 100%;
}

.reconfirm-btn {
  padding: 13px 31px;
  max-width: 132px;
  width: 100%;
}

.phone-call-form {
  margin-bottom: 8px;
}

.send-call-form {
  background: #f8f8fa;
  padding: 16px;
}

.call-send-text {
  color: #53565a !important;
}

.help-block {
  color: #a94442 !important;
}

.mobile-primary-method-container {
  display: flex;
  padding-left: 24px;
  margin-top: 30px;
}

.mobile-primary-method {
  margin-top: 29px;
}

.mobile-primary-method-label {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: block;
  margin-bottom: 13px;
}

.mobile-primary-method-radio {
  padding-left: 24px;
}

.mobile-primary-method-radio-first {
  margin-right: 22px;
}

.mobile-primary-method-radio input~.checkmark {
  top: 2px;
}

.activate-list-item {
  height: 190px;
  left: -1px;
}

.form-container-method {
  margin-bottom: 40px;
}

.second-method-container {
  margin-bottom: 14px;
}

.input-form-label-description {
  display: block;
  color: #53565a;
  margin-bottom: 16px;
}

.select-language-label {
  margin-bottom: 8px;
}

.checkbox-form-description {
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
  display: block;
  margin-bottom: 30px;
}

.mobile-primary-method-description {
  font-size: 12px;
  line-height: 16px;
  display: block;
  color: #53565a;
}

.first-activate-title {
  margin-bottom: 30px;
}

.mat-menu-panel {
  max-height: 245px !important;
  width: 370px !important;
  position: fixed !important;
}
.customfooter {
  margin: auto;

}
.my-label,
.my-input,
.my-autocomplete .mat-option {
  height: 24px;
  max-height: 34px;
  line-height: 24px; 
  width: fit-content;
}
.custom
{
  border : 0 !important;
}
@media All and (max-width: 1680px) {
  .self-service-main-container {
    margin-left: 10%;
  }
  .customfooter {
    margin-top: 25%;
  }
}

@media All and (max-width: 830px) {
  .label-success-authentication::before {
    left: 134px;
  }
}

@media All and (max-width: 357px) {
  .label-success-authentication::before {
    left: 253px;
  }
}

@media All and (max-width: 530px) {
  .self-service-main-container {
    margin-left: 0px;
  }
  .customfooter {
    margin-top: 3%;
  }

  .self-service-form-container {
    padding: 0 16px 89px 16px;
  }
}

@media All and (max-width: 1440px) {
  .form {
    margin-top: 64px;
  }
  .customfooter {
    margin-top: 20%;
  }
}

@media All and (max-width: 1140px) {
  .img-container {
    width: 500px;
  }
  .img-container {
    background-position: center;
  }
}

@media All and (max-width: 1139px) {
  .img-container{
    display: none;
  }

  .form-container {
    justify-content: left;
  }

  .info-terms {
    width: 100%;
    max-width: none;
  }
  .customfooter {
    margin-top: 20%;
  }

  .form-container {
    width: 100%;
  }

  .main-container {
    max-width: 824px;
  }
}

@media All and (max-width: 830px) {
  .header-admin-page-form {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .logo {
    margin-bottom: 32px;
  }

  .customfooter {
    margin-top: 15%;
  }

  .info-terms {
    padding-left: 16px;
    padding-right: 16px;
  }

  .form-container {
    padding: 0 16px 182px 16px;
  }

  .footer {
    padding-bottom: 48px;
  }
}

@media All and (max-width: 320px) {
  .form-container {
    padding: 0 16px 64px 16px;
  }
  .customfooter {
    margin-top: 2%;
  }
}

.input {
  border: 1px solid #d0d0ce;
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  padding-top: 14px;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 47px;
  height: 48px;
  width: 100%;
}

.input:focus {
  border: 1px solid #000000;
  color: #000000;
}

.input.error {
  border: 1px solid #a94442;
}

.input-active {
  border: 1px solid #000000;
  color: #000000;
}

.invalid-text {
  display: none;
  font-size: 12px;
  color: #a94442;
  margin-top: 8px;
}

.input-active~.btn-unmask {
  display: block;
}

.btn-unmask {
  display: none;
  background-image: url('assets/img/login-page/non-visible.svg');
  background-repeat: no-repeat;
  width: 24px;
  position: relative;
  height: 24px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  right: 10px;
  background-color: transparent;
  position: absolute;
  color: transparent;
  top: 12px;
  border: none;
  outline: none;
  z-index: 11;
}

.btn-unmask-active {
  background-image: url('assets/img/login-page/visible.svg');
}

.input.error~.invalid-text {
  display: block;
}

.input.disabled {
  background-color: #e6e6e6;
  border: none;
  color: #97999b;
}

.link {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  text-decoration: none;
}

.link-description {
  font-size: 12px;
}

.link:active,
.link:focus,
.link:visited {
  outline: none;
}

.link-info {
  padding-right: 10px;
  font-size: 12px;
  color: #00a3e0;
  font-weight: 700;
}

.link-info:active,
.link-info:focus,
.link-info:visited {
  outline: none;
}

.header-admin-page-form {
  display: flex;
}

.btn-unmask,
.btn-unmask:active,
.btn-unmask:focus {
  outline: none;
}

.textarea-input {
  width: 100%;
  border: 1px solid #d0d0ce;
  padding-top: 14px;
  padding-left: 15px;
  font-size: 14px;
  padding-right: 47px;
  line-height: 20px;
  color: #97999b;
  min-height: 112px;
  height: 100%;
  resize: none;
}

::-ms-reveal {
  display: none;
}

.input-password.active~.btn-unmask {
  display: block;
}

.input:-ms-input-placeholder {
  color: #97999b !important;
}

.textarea-input:-ms-input-placeholder {
  color: #97999b !important;
}

/*#Customization by DIS team*/

input.ng-invalid.ng-touched {
  border: 1px solid #a94442;
}

input.ng-invalid-patern.ng-touched {
  border: 1px solid #a94442;
}

select.ng-invalid.ng-touched {
  border: 1px solid #a94442;
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  padding: 8px ;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-size: 14px;
  font-family: 'Open Sans';
}
.glyphicon-trash, .glyphicon-ok, .glyphicon-pause

{
  color:rgb(60, 31, 31) !important;
}
.mat-cell
{
  font-size: 12px;
  font-family: 'Open Sans';
}
.main-text-error {
  font-size: 14px;
  line-height: 20px;
  color: #a94442;
  font-weight: 400;
}

.main-text-info {
  font-size: 14px;
  line-height: 20px;
  color: #a94442;
  font-weight: 400;
}